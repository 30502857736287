
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
    background: transparent; 
    position: relative;
    overflow: hidden;
  }
  
  
  .glass-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    backdrop-filter: blur(20px); 
    background: rgba(246, 246, 246, 0.693); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 30px rgba(230, 225, 225, 0.1); 
  }
  
  /* Bar Loader */
  .bar-loader-container {
    display: flex;
    gap: 5px;
  }
  
  .bar {
    width: 5px;
    height: 20px;
    background-color: #343333; 
    border-radius: 2px;
    animation: barAnimation 1s infinite ease-in-out;
  }
  
  .bar:nth-child(1) {
    animation-delay: 0s;
  }
  
  .bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .bar:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .bar:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  /* Text styling */
  .loading-text {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    color: #333; 
    opacity: 0.8;
    animation: fadeInText 1.5s ease-in-out infinite;
  }
  
  /* Bar Animation */
  @keyframes barAnimation {
    0% {
      height: 20px;
      opacity: 0.6;
    }
    50% {
      height: 40px;
      opacity: 1;
    }
    100% {
      height: 20px;
      opacity: 0.6;
    }
  }
  
  /* Text Animation */
  @keyframes fadeInText {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  