.settingsPage {
    .leftPanel {
        width: 15%;
        min-width: 200px;
        // background-color: #f9f9f9;
        // border-right: 1px solid #e0e0e0;

        @media screen and (max-width: 567px) {
            width: 70px;
            min-width: 40px;

            .settingsNavItem {
                p {
                    display: none;
                }
            }
        }

        .MuiInput-root {
            padding-left: 12px;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            &::before {
                border: none;
                height: 34px;
            }

            &::after {
                border-bottom: none;
            }

            &:focus-within {
                box-shadow: 0 0 0 2px #1976d2;
            }

            
        }

        .MuiTypography-root {
            line-height: 1.2;
            // color: #333;
            font-weight: 500;
        }
    }

    .white-container {
        height: calc(100vh - 123px);
        overflow-y: auto;  
        padding: 20px 5px;    
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    
    }

    .rightPanel {
        width: 90%;
        // background-color: #fff;
    }

    .settingsNavItem {
        // display: flex;
        // align-items: center;
        padding: 7px 2px;
        cursor: pointer;
        color: #555;
        font-size: 14px;
        transition: color 0.3s, background-color 0.3s, font-weight 0.3s, transform 0.2s;

        &:hover {
            color: #004a7c;
            background-color: #f0f4f8;
            font-weight: 600;
            // transform: scale(1.05); 
        }
    }

    .selColor {
        color: #004a7c;
        background-color: #e0f7fa;
        font-weight: 600;
        border-radius: 5px;
    }
    .subNavItem{
        padding: 8px 0 6px 16px;
        border-radius: 16px;
        margin: 0 10px 0 26px;
    }
    .subItemSelected {
        background-color: #a9d3ee; 
        color: #000;
      }
    .userTabs {
        .MuiTab-root {
            min-height: 48px;
            font-size: 14px;
            color: #666;
            font-weight: 500;
            padding: 10px 15px;

            &.Mui-selected {
                color: #1976d2;
                font-weight: bold;
            }
        }
    }
}

.dealItem {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    padding: 6px;
    // border: 1px solid #e0e0e0;
    border-radius: 8px;
    // background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
    margin-bottom: 10px;

    &:hover {
        box-shadow: 0 0px 8px rgba(6, 5, 5, 0.1);
    }
    
    .dragIcon {
        // color: #555;
        margin-bottom: -6px;
    }

    .itemInput, .descriptionInput {
        width: 100%;

        .MuiInputBase-root {
            background-color: #fff;
            border-radius: 8px;

            & fieldset {
                border: 1px solid #ddd;
            }

            &:hover fieldset {
                border-color: #1976d2;
            }

            &.Mui-focused fieldset {
                border-color: #1976d2;
                box-shadow: 0 0 4px rgba(25, 118, 210, 0.3);
            }
        }
    }

    .activeSwitch {
        font-size: 14px;
        color: #666;

        .MuiSwitch-root {
            color: #1976d2;
        }
    }

    .deleteIcon {
        color: #d32f2f;
    }
}

